import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomCard from '../Cards/CustomCard';
import './DataTable.scss'
import useNotification from '../common/useNotification';

const SORT_ORDER = {
    Ascending: 0,
    Descending: 1
}

const DataTable = ({
    title,
    description,
    columns,
    staticData,
    loadData,
    getData,
    reloadData,
    resetReloadData,
    density,
    loading,
    getRowId,
    initialSort,
    serverSide,
    additionalParamsData,
    ...otherProps
}) => {
    const { showError } = useNotification();
    const [data, setData] = useState([]);
    const [serverSideData, setServerSideData] = useState({ rows: [], totalCount: 0 });
    const [isLoading, setLoading] = useState(false);
    const [tableInited, setTableInited] = useState(false);
    const [sortModel, setSortModel] = React.useState(initialSort ? [{ ...initialSort }] : []);
    const [filterModel, setFilterModel] = React.useState({});
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });

    useEffect(() => {
        if(getData && ((loadData && !tableInited) || reloadData || filterModel || paginationModel))
        {
            setLoading(true);
            const queryModel = {
                sortModel: {
                    sortBy: sortModel[0]?.field, 
                    sortOrder: sortModel[0]?.sort === 'asc' ? SORT_ORDER.Ascending : SORT_ORDER.Descending
                },
                filterModel: filterModel,
                ...paginationModel,
                ...(additionalParamsData ?? {})
            }
            getData(queryModel).then(res => {
                setLoading(false);
                const data = res.data;
                if(res.status === 200)
                {
                    if(serverSide)
                    {
                        if(data.success && data.data)
                        {
                            setServerSideData(data.data);
                        }
                        else
                            showError('Došlo je do greške prilikom učitavanja podataka tabele! (SS)')
                    }
                    else
                        setData(res.data);
                }
                else
                    showError('Došlo je do greške prilikom učitavanja podataka tabele!');
            }).catch(e => {
                setLoading(false);
                console.error(e);
            });

            if(!tableInited)
                setTableInited(true);

            if(reloadData)
                resetReloadData();
        }
    }, [loadData, reloadData, sortModel, filterModel, paginationModel]);


    const getColumnsVisibilityModel = () => {
        if(!columns) return null;
        const columnVisibilityModel = columns.filter(x => x.hide).map(x => x.field).reduce((prev, curr) => ({ ...prev, [curr]: false }), {});
        return columnVisibilityModel;
    }

    return (  
        <CustomCard>
            { title && (<p className="card-title">{title}</p>)}
            { description && (<p className="card-title-desc">{description}</p>)}
            <div style={{  width: '100%' }}>
                <DataGrid
                    density={density ?? 'standard'}
                    style={{
                        backgroundColor: '#2a3042',
                        color: 'inherit'
                    }}
                    sx={{
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: '#32394e !important',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: '600 !important',
                        },
                        '.MuiTablePagination-root': {
                            color: 'inherit'
                        },
                        '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                            marginTop: '14px',
                            marginBottom: '16px',
                        }
                    }}
                    rows={staticData ?? (serverSide ? serverSideData.rows : data)}
                    columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: initialSort ?  [ initialSort ] : undefined
                        },
                        columns: {
                            columnVisibilityModel: getColumnsVisibilityModel()
                        }
                    }}
                    pageSizeOptions={[5, 10, 20, 50]}
                    disableRowSelectionOnClick
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    slots={{ 
                        toolbar: GridToolbar
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    loading={isLoading || loading}
                    autoHeight
                    getRowId={getRowId ?? undefined}
                    sortingMode={serverSide ? 'server' : 'client'}
                    onSortModelChange={setSortModel}
                    filterMode={serverSide ? 'server' : 'client'}
                    onFilterModelChange={setFilterModel}
                    paginationMode={serverSide ? 'server' : 'client'}
                    onPaginationModelChange={setPaginationModel}
                    paginationModel={paginationModel}
                    rowCount={serverSide ? serverSideData.totalCount : undefined}
                    {...otherProps}
                />
            </div>
        </CustomCard>
    );
}

DataTable.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    columns: PropTypes.array.isRequired,
    staticData: PropTypes.array,
    loadData: PropTypes.bool,
    getData: PropTypes.func,
    reloadData: PropTypes.bool,
    resetReloadData: PropTypes.func,
    getRowId: PropTypes.func,
    initialSort: PropTypes.object
}
 
export default DataTable;