import moment from 'moment';

export const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
}

export const formatDateTime = (date) => {
    if(!date)
        return null;

    return moment(date).format('YYYY-MM-DD HH:mm');
}

export const formatDateTimeWithSeconds = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
}